$(function() {

    var updatedStudents = function() {

      // Initial Values
      var siteId = parseInt($('#coach option:selected').attr('data-site'));
      var value = parseInt($('#coach option:selected').attr('value'));
      var hasResults = false;

      // Temporarily enables coach select and removes helper text
      $('#select-students').removeAttr('disabled');
      $('.select-coach').css('display', 'none');

      // Loops through coaches and disables ones that don't match network site id
      $('#select-students option').each(function(){
        var coachId = parseInt($(this).attr('data-site'));
        var noCoach = parseInt($(this).attr('data-coach'));



        if(coachId == siteId && isNaN(noCoach) || !isNaN(noCoach) && noCoach == value) {
          $(this).prop('disabled', false);
          hasResults = true;
        } else {
          $(this).prop('disabled', true);
        }
      });

      if(!hasResults) {
        $('#select-students').prop('disabled', true);
		$('#select-students').val(null).trigger('change');
        $('.js-studentSelectWrapper .no-results').css('display', 'inline');
      } else {
        $('#select-students').prop('disabled', false);
        $('.js-studentSelectWrapper .no-results').css('display', 'none');
      }

    }

    // If network is already selected, enable coach select
    if($('#coach').length) {
      if ($('#coach').val()) {
        $('#coach').removeAttr('disabled');
        updatedStudents();
      }
    }

    // Add Student Form Validation
    $('#coach').change(function(){
      $('#select-students option').each(function(){
        $(this).removeAttr('selected');
      });
      updatedStudents();
      // Select2 reset
      $("#select-students").select2('destroy');
      $("#select-students").select2();
    });

    $('#select-students').change(function(){
        var selectedStudent = $('#select-students option:selected').attr('value');

        $('#select-students option[value="' + selectedStudent + '"]').attr('selected', 'selected');
    });

	var $toggles  = $('.toggle');
	var $points   = $('.tasks.points');
	var $total    = $points.find('.total .points');
	var $active   = $points.find('.active .points');
	var $inactive = $points.find('.inactive .points');

	var count_points = function() {
		var active       = 0;
		var inactive     = 0;
		var total_points = 0;

		$('.toggle').each(function(i, toggle) {
			var $toggle = $(toggle);
			var points  = parseInt($toggle.parents('tr').find('.points').text());

			if ($toggle.hasClass('disabled') || $toggle.hasClass('active')) {
				total_points = total_points + points;
				active++;

			} else {
				inactive++;
			}
		});

		$active.text(active);
		$inactive.text(inactive);
		$total.text(total_points);
	};

	$toggles.click(function() {
		var $toggle = $(this);
		var points  = parseInt($toggle.parents('tr').find('.points').text());

		//
		// These changes appear inversed because it's operating on the target state not the current
		// current state.  That is, if the toggle is currently active, then it needs to remove points because
		// a click implies it's going inactive
		//

		if ($toggle.hasClass('active')) {
			$active.text(parseInt($active.text()) - 1);
			$inactive.text(parseInt($inactive.text()) + 1);
			$total.text(parseInt($total.text()) - points);

		} else {
			$active.text(parseInt($active.text()) + 1);
			$inactive.text(parseInt($inactive.text()) - 1);
			$total.text(parseInt($total.text()) + points);

		}
	});

	count_points();

	$('.cohort-select').change(function(){
		$('.cohort-switch').show();
	});

	$('.js-addStudent').on('change', function() {
		if ($('.js-addStudent').val()) {
			$('.js-addStudentButton').prop('disabled', false)
		} else {
			$('.js-addStudentButton').prop('disabled', true)
		}

	});
});
